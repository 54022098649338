<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="角色授权"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <div class="table-wrapper">
            <a-spin :spinning="spinning">
                <div class="row header">
                    <div class="system-module">系统模块</div>
                    <div class="group-menu-wrapper header">
                        <div class="menu-group">菜单分组</div>
                        <div class="menu-btn-wrapper header">
                            <div class="menu-perms">菜单权限</div>
                            <div class="btn-perms">操作权限</div>
                        </div>
                    </div>
                </div>
                <div class="row" v-for="(_module, index) in menuInfos" :key="index">
                    <div class="system-module" style="display: flex">
                        {{ _module.name }}
                    </div>
                    <div class="group-menu-wrapper">
                        <template
                            v-for="(group, groupIndex) in _module.children && _module.children"
                            :key="groupIndex"
                        >
                            <div class="group-menu-content">
                                <div class="menu-group">
                                    {{ group.name }}
                                </div>
                                <div class="menu-btn-wrapper">
                                    <template
                                        v-for="(menu, index) in group.children && group.children"
                                        :key="index"
                                    >
                                        <div class="menu-btn-content">
                                            <div class="menu-perms">
                                                <a-checkbox
                                                    :key="index"
                                                    v-model:checked="menu.havePerm"
                                                    @change="e => changeCheck(e, menu.code)"
                                                >
                                                    {{ menu.name }}
                                                </a-checkbox>
                                            </div>
                                            <div class="btn-perms">
                                                <a-checkbox
                                                    v-for="(
                                                        btn, btnIndex
                                                    ) in menuCode2UserOperatePermInfos[menu.code]"
                                                    :key="btnIndex"
                                                    v-model:checked="btn.havePerm"
                                                    @change="e => changeCheck(e, btn.code)"
                                                >
                                                    {{ btn.showName }}
                                                </a-checkbox>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </a-spin>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import { apiSavePerm, apiRoleIndexData } from "@/api";
import { showMessage } from "@/utils/common";
export default defineComponent({
    props: ["authorizationId"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            spinning: false,
            formData: {
                name: "",
                code: "",
                desc: "",
            },
            menuCode2UserOperatePermInfos: [],
            menuInfos: [],
            currentModule: 0,
            savePermList: [],
        });

        const handleCancel = () => {
            emit("update:authorizationId");
        };

        const handleSubmit = async () => {
            state.spinning = true;
            let res = await apiSavePerm({
                roleId: props.authorizationId,
                permCodes: state.savePermList,
            });
            state.spinning = false;
            if (res.status === "SUCCESS") {
                emit("update:authorizationId");
                showMessage("success", res.msg);
            }
        };

        const getRoleIndexData = async roleId => {
            state.spinning = true;
            let res = await apiRoleIndexData(roleId);
            state.spinning = false;
            if (res.status === "SUCCESS") {
                let { menuCode2UserOperatePermInfos, menuInfos } = res.data;
                state.menuCode2UserOperatePermInfos = menuCode2UserOperatePermInfos;
                state.menuInfos = menuInfos;
            }
            state.menuInfos.forEach(_module => {
                _module?.children?.forEach(group => {
                    group?.children?.forEach(menu => {
                        if (menu.havePerm) {
                            state.savePermList.push(menu.code);
                        }
                        if (state.menuCode2UserOperatePermInfos[menu.code] != undefined) {
                            state.menuCode2UserOperatePermInfos[menu.code].forEach(btn => {
                                if (btn.havePerm) {
                                    state.savePermList.push(btn.code);
                                }
                            });
                        }
                    });
                });
            });
        };

        const changeCheck = (e, code) => {
            if (e.target.checked) {
                state.savePermList.push(code);
            } else {
                let index = state.savePermList.findIndex(_code => _code === code);
                index !== -1 && state.savePermList.splice(index, 1);
            }
        };

        watch(
            () => props.authorizationId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getRoleIndexData(newVal);
                    state.savePermList = [];
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            changeCheck,
        };
    },
});
</script>

<style lang="less" scoped>
.table-wrapper {
    border-top: 0.5px solid hsla(0, 0%, 50%, 0.2);
    border-left: 0.5px solid hsla(0, 0%, 50%, 0.2);
}
.row {
    display: flex;
    > div {
        box-sizing: border-box;
        border-right: 0.5px solid hsla(0, 0%, 50%, 0.2);
        border-bottom: 0.5px solid hsla(0, 0%, 50%, 0.2);
    }
    .system-module {
        flex: 1;
        padding: 10px;
    }
    .group-menu-wrapper {
        flex: 8;
        &.header {
            display: flex;
        }
        .group-menu-content {
            display: flex;
            border-bottom: 0.5px solid hsla(0, 0%, 50%, 0.2);
        }
        .menu-group {
            flex: 1;
            display: flex;
            padding: 10px;
            flex-direction: column;
            border-right: 0.5px solid hsla(0, 0%, 50%, 0.2);
        }
        .menu-btn-wrapper {
            flex: 3;
            &.header {
                display: flex;
            }
            .menu-btn-content {
                display: flex;
                border-bottom: 0.5px solid hsla(0, 0%, 50%, 0.2);
            }
            .menu-perms {
                flex: 1;
                padding: 10px;
                display: flex;
                flex-direction: column;
                border-right: 0.5px solid hsla(0, 0%, 50%, 0.2);
                .ant-checkbox-wrapper {
                    margin-left: 0;
                }
            }
            .btn-perms {
                flex: 1;
                padding: 10px;
            }
        }
    }
}
.header {
    height: 48px;
    font-weight: bolder;
}
</style>
