<template>
    <a-modal
        v-model:visible="visible"
        title="用户列表"
        width="1000px"
        okText="确定"
        :destroyOnClose="true"
        @cancel="handleCancel"
    >
        <a-input-search
            v-model:value="displayName"
            placeholder="请输入姓名以查询"
            @search="getTabelData"
            class="search-input"
            enter-button="搜索"
        />
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :loading="loading"
            :pagination="pagination"
            @change="paginationChange"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'deptInfos'">
                    <div v-for="(item, index) in record.deptInfos" :key="index">
                        <a-tag :style="{ opacity: item.main ? 1 : 0 }" color="green">主</a-tag>
                        {{ item.sysDept?.name }} - {{ item.sysPost?.name }}
                    </div>
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiUserList } from "@/api";
import Select from "@/components/Select";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "工号",
        dataIndex: "jobNo",
    },
    {
        title: "用户名",
        dataIndex: "username",
    },
    {
        title: "姓名",
        dataIndex: "displayName",
    },
    {
        title: "部门-岗位",
        dataIndex: "deptInfos",
        width: 350,
    },
];

export default defineComponent({
    props: ["roleId"],
    emits: ["update:roleId"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            tableData: [],
            displayName: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 15,
                showSizeChanger: false
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:roleId", 0);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                displayName,
            } = state;
            let res = await apiUserList({
                pageNum,
                pageSize,
                queryParam: {
                    roleId: props.roleId,
                    displayName,
                    enabled: true,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList;
            }
        };

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        watch(
            () => props.roleId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
        };
    },
});
</script>

<style lang="less" scoped>
.search-input {
    margin-bottom: 16px;
    width: 300px;
}
</style>
