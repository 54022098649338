<template>
    <div class="container">
        <UpDateModal :upDateRow="upDateRow" @eventUpdateOk="eventUpdateOk" />
        <RoleAuthorization v-model:authorizationId="authorizationId" />
        <UserList v-model:roleId="roleId" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1000 }"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'users'">
                        <a @click="() => (roleId = record.id)">查看</a>
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => openUpDateModal(record)" v-perm="'btn:role-edit'">
                                编辑
                            </a>
                            <a
                                @click="() => openRoleAuthorization(record.id)"
                                v-perm="'btn:role-add-perm'"
                            >
                                角色授权
                            </a>
                            <a @click="deleteRow(record.id)" v-perm="'btn:role-delete'">删除</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            v-perm="'btn:role-add'"
                            type="primary"
                            @click="() => openUpDateModal({ id: null })"
                        >
                            新增
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import RoleAuthorization from "./roleAuthorization";
import UserList from "./userList";
import { confirmModal, showMessage } from "@/utils/common";
import { apiRoleList, apiRoleEnable, apiDeleteRole } from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        RoleAuthorization,
        UserList,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
            },
            {
                title: "角色编码",
                dataIndex: "code",
                width: 180,
            },
            {
                title: "角色名称",
                dataIndex: "name",
            },
            {
                title: "角色描述",
                dataIndex: "description",
            },
            {
                title: "包含用户",
                dataIndex: "users",
                width: 100,
            },
            {
                title: "启用状态",
                dataIndex: "enabled",
                key: "enabled",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 180,
            },
        ];

        const state = reactive({
            authorizationId: 0,
            upDateRow: null,
            params: null,
            data: [],
            loading: false,
            roleId: 0,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiRoleList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const openUpDateModal = row => {
            state.upDateRow = row;
        };

        const openRoleAuthorization = id => {
            state.authorizationId = id;
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "角色禁用后，之后将无法在业务流程中使用该角色信息，是否确认禁用？"
                : "角色启用后，该角色可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiRoleEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "角色删除后将无法恢复，是否确认删除该角色？",
                onOk: async () => {
                    let res = await apiDeleteRole([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        return {
            columns,
            ...toRefs(state),
            openUpDateModal,
            openRoleAuthorization,
            changeSwitch,
            deleteRow,
            search,
            paginationChange,
            eventUpdateOk,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
